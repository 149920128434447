/* eslint-disable import/no-extraneous-dependencies */
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import TasksList from "../components/TasksList";
import callApi from "../utils/callApi";

function TasksListPage() {
  // const user = {
  //   id: "304",
  //   username: "Khalil ABID",
  //   email: "khalil.abid@piterion.com",
  //   "groups ": {
  //     2: "2",
  //     7: "7",
  //     8: "8",
  //     23: "23",
  //     36: "36",
  //     38: "38",
  //   },
  //   groupNames: [
  //     "Registered",
  //     "Administrator",
  //     "Super Users",
  //     "Grp_Admin_WORLD",
  //     "Grp_Admin_PI_WORLD",
  //     "Grp_HR_DEU",
  //   ],
  // };

  // Access Joomla user information from the global variable
  const { joomlaUser } = window;
  const [status, setStatus] = useState("CREATED");

  // Fetch tasks from the API
  const { data: tasksList, isLoading: tasksDataIsLoading } = useQuery({
    queryKey: ["getAllTasks", status],
    queryFn: async () => {
      if (joomlaUser) {
        console.log("Joomla User:", joomlaUser);

        // Assuming joomlaUser has a 'groups' field with an array of group IDs
        const { groupNames, email } = joomlaUser;

        const tasks = await callApi("task/search", "post", {
          groupNames,
          email,
          status: status === "ALL" ? "" : status,
        });

        return tasks;
      }

      return [];
    },
  });

  return (
    <div className="App">
      {tasksDataIsLoading && (
        <div className="overlay">
          <div className="overlay-content">
            <p>Loading data...</p>
            {/* You can add a loading spinner or other loading animation here */}
          </div>
        </div>
      )}

      <div style={{ display: "flex", marginTop: "50px", marginBottom: "20px" }}>
        Filter by status :
        <select
          style={{
            width: "150px",
            marginLeft: "20px",
          }}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="CREATED">CREATED</option>
          <option value="COMPLETED">COMPLETED</option>
          <option value="ALL">All</option>
        </select>
      </div>
      <TasksList data={tasksList} user={joomlaUser} />
    </div>
  );
}

export default TasksListPage;
