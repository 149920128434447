/* eslint-disable import/no-extraneous-dependencies */
import { useQuery } from "@tanstack/react-query";
import BPMNDiagram from "../components/BPMNDiagram";
import callApi from "../utils/callApi";

function ProcessesList() {
  /* get all processes definitions */
  const { data: processesList, isLoading: processesDataIsLoading } = useQuery({
    queryKey: ["getAllProcesses"],
    queryFn: async () => {
      const processes = await callApi("process/getProcessDefinitions", "get");

      return processes;
    },
  });

  return (
    <div className="App">
      {processesDataIsLoading && "Loading data..."}
      {!processesDataIsLoading && <BPMNDiagram processesList={processesList} />}
    </div>
  );
}

export default ProcessesList;
