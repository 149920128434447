/* eslint-disable no-return-assign */
/* eslint-disable react/button-has-type */
/* eslint-disable no-underscore-dangle */
import { useState, useRef } from "react";
import DateEditor from "react-tabulator/lib/editors/DateEditor";
import MultiValueFormatter from "react-tabulator/lib/formatters/MultiValueFormatter";
import "react-tabulator/lib/styles.css"; // default theme
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css"; // use Theme(s)
import { ReactTabulator, reactFormatter } from "react-tabulator";

function SimpleButton({ cell }) {
  const rowData = cell._cell.row.data;
  const cellValue = cell._cell.value || "Edit | Show";
  return <button onClick={() => alert(rowData.name)}>{cellValue}</button>;
}

const colorOptions = {
  "": "&nbsp;",
  red: "red",
  green: "green",
  yellow: "yellow",
};
const petOptions = [
  { id: "cat", name: "cat" },
  { id: "dog", name: "dog" },
  { id: "fish", name: "fish" },
];

const editableColumns = [
  {
    title: "Name",
    field: "name",
    width: 150,
    editor: "input",
    headerFilter: "input",
  },
  {
    title: "Age",
    field: "age",
    hozAlign: "left",
    formatter: "progress",
    editor: "progress",
  },
  {
    title: "Favourite Color",
    field: "color",
    editor: "select",
    editorParams: {
      allowEmpty: true,
      showListOnEmpty: true,
      values: colorOptions,
    },
    headerFilter: "select",
    headerFilterParams: { values: colorOptions },
  },
  {
    title: "Date Of Birth",
    field: "dob",
    sorter: "date",
    editor: DateEditor,
    editorParams: { format: "MM/DD/YYYY" },
  },
  {
    title: "Pets",
    field: "pets",
    sorter: (a, b) => a.toString().localeCompare(b.toString()),
    editorParams: { values: petOptions },
    formatter: MultiValueFormatter,
    formatterParams: { style: "PILL" },
  },
  {
    title: "Passed?",
    field: "passed",
    hozAlign: "center",
    formatter: "tickCross",
    editor: true,
  },
];
const tableData = [
  {
    id: 1,
    name: "Oli Bob",
    age: "12",
    color: "red",
    dob: "01/01/1980",
    rating: 5,
    passed: true,
    pets: ["cat", "dog"],
  },
  {
    id: 2,
    name: "Mary May",
    age: "1",
    color: "green",
    dob: "12/05/1989",
    rating: 4,
    passed: true,
    pets: ["cat"],
  },
  {
    id: 5,
    name: "Margret Marmajuke",
    age: "16",
    color: "yellow",
    dob: "07/01/1999",
    rating: 4,
    passed: false,
  },
  {
    id: 6,
    name: "Van Ng",
    age: "37",
    color: "green",
    dob: "06/10/1982",
    rating: 4,
    passed: true,
    pets: ["dog", "fish"],
  },
  {
    id: 7,
    name: "Duc Ng",
    age: "37",
    color: "yellow",
    dob: "10/10/1982",
    rating: 4,
    passed: true,
    pets: ["dog"],
  },
];
function TabulatorSample() {
  const [data, setData] = useState(tableData);
  const [selectedName, setSelectedName] = useState("");
  const tableRef = useRef(null);

  const columns = [
    { title: "Name", field: "name", width: 150 },
    { title: "Age", field: "age", hozAlign: "left", formatter: "progress" },
    { title: "Favourite Color", field: "color" },
    { title: "Date Of Birth", field: "dob" },
    { title: "Rating", field: "rating", hozAlign: "center", formatter: "star" },
    {
      title: "Passed?",
      field: "passed",
      hozAlign: "center",
      formatter: "tickCross",
    },
    {
      title: "Custom",
      field: "custom",
      hozAlign: "center",
      editor: "input",
      formatter: reactFormatter(
        <SimpleButton
          onSelect={(name) => {
            setSelectedName(name);
            alert(name);
          }}
        />,
      ),
    },
  ];

  const rowClick = (e, row) => {
    setSelectedName(row.getData().name);
  };

  const setTableData = () => {
    setData(data); // set your data here
  };

  const clearTableData = () => {
    setData([]);
  };

  const options = {
    height: 150,
    movableRows: true,
    movableColumns: true,
  };

  return (
    <div>
      <ReactTabulator
        onRef={(ref) => (tableRef.current = ref)}
        columns={columns}
        data={data}
        events={{
          rowClick,
        }}
        options={options}
        data-custom-attr="test-custom-attribute"
        className="custom-css-class"
      />
      <i>
        Selected Name: <strong>{selectedName}</strong>
      </i>

      <h3>
        Asynchronous data: (e.g. fetch) -{" "}
        <button onClick={setTableData}>Set Data</button>
        <button onClick={clearTableData}>Clear</button>
      </h3>
      <ReactTabulator columns={columns} data={data} />

      <h3>Editable Table</h3>
      <ReactTabulator
        columns={editableColumns}
        data={data}
        footerElement={<span>Footer</span>}
      />
    </div>
  );
}

export default TabulatorSample;
