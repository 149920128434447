/* eslint-disable import/no-extraneous-dependencies */
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import TabulatorSample from "./components/TabulatorSample";
import TasksListPage from "./pages/TasksList";
import ProcessesList from "./pages/ProcessesList";

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <Router>
      <Routes>
        <Route path="/tasks" element={<TasksListPage />} />
        <Route path="/processes" element={<ProcessesList />} />
        <Route path="/tabulator-sample" element={<TabulatorSample />} />
      </Routes>
    </Router>
  </QueryClientProvider>,
);
