import Modal from "react-modal";

function ModalContainer({ children, isModalOpen, closeModal }) {
  const customModalStyles = {
    padding: "10px",
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)", // Black with 70% opacity
      zIndex: "9999",
    },
  };
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Task Form Modal"
      style={customModalStyles}
    >
      <div style={{ textAlign: "right" }}>
        <button
          type="button"
          onClick={closeModal}
          style={{ background: "none", border: "none", cursor: "pointer" }}
        >
          <span
            style={{ fontSize: "20px", fontWeight: "bold", color: "black" }}
          >
            X
          </span>
        </button>
      </div>
      {children}
    </Modal>
  );
}

export default ModalContainer;
