/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import BpmnViewer from "bpmn-js";
import "./BPMNDiagram.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import callApi from "../utils/callApi";

function BPMNDiagram({ processesList }) {
  const [selectedValue, setSelectedValue] = useState("");
  const [processInstanceIsLoading, setProcessInstanceIsLoading] =
    useState(false);

  const handleChange = (event) => {
    setSelectedValue(JSON.parse(event.target.value));
  };

  const { data: xml, isLoading: xmlIsLoading } = useQuery({
    queryKey: ["getXml", selectedValue],
    queryFn: async () => {
      if (selectedValue !== "" && selectedValue !== undefined) {
        try {
          const processXml = await callApi(
            `process/getXml?processDefinitionKey=${selectedValue?.key}`,
            "get",
          );

          return processXml;
        } catch (error) {
          toast.error("Error fetching XML for BPMN diagram");
          console.error("Error fetching XML:", error.message);
          throw error;
        }
      }
      return {};
    },
  });

  const viewerRef = useRef(null);
  const bpmnContainer = document.getElementById("js-canvas");

  useEffect(() => {
    if (
      !xmlIsLoading &&
      xml?.length > 0 &&
      xml !== undefined &&
      xml?.length !== undefined
    ) {
      if (!viewerRef.current) {
        viewerRef.current = new BpmnViewer({
          container: bpmnContainer,
          height: 600,
        });
      }

      viewerRef.current
        .importXML(xml)
        .then(({ warnings }) => {
          console.log("Success!", warnings);

          viewerRef.current.get("canvas").zoom("fit-viewport");
          // Other operations...
        })
        .catch((err) => {
          console.error("Error importing XML:", err.warnings, err.message);
        });
    }
  }, [xml, xmlIsLoading]);

  const handleCreateProcessInstance = async () => {
    if (selectedValue !== "" && selectedValue !== undefined) {
      setProcessInstanceIsLoading(true);
      try {
        const process = await callApi(
          `process/createProcessInstanceWithResult?bpmnProcessId=${selectedValue?.bpmnProcessId}`,
          "get",
        );
        if (process !== undefined)
          toast.success(
            "Creating process instance and tasks, it will takes a few minutes Please wait...",
          );
      } catch (error) {
        setProcessInstanceIsLoading(false);
        console.error("Error fetching process instance:", error.message);
      } finally {
        setProcessInstanceIsLoading(false);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <label htmlFor="bpmnProcessSelect">Select a BPMN Process:</label>
      <select
        id="bpmnProcessSelect"
        value={JSON.stringify(selectedValue)}
        onChange={handleChange}
      >
        <option value="">Select a process</option>
        {processesList?.map((item) => (
          <option key={item.key} value={JSON.stringify(item)}>
            {item.name}
          </option>
        ))}
      </select>

      <div className="canvas">
        <div id="js-canvas" />
      </div>
      {xml?.length !== undefined && (
        <button
          disabled={processInstanceIsLoading === true}
          className="btn btn-primary btn-sm btn-take-form"
          onClick={() => handleCreateProcessInstance()}
          type="button"
          style={{ display: "block", margin: "0 auto" }}
        >
          Start Process
        </button>
      )}
    </>
  );
}

export default BPMNDiagram;
