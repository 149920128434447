/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useRef, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Form } from "@bpmn-io/form-js";
import "@bpmn-io/form-js/dist/assets/form-js.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import callApi from "../utils/callApi";

function RenderForm({ taskId, formInputs, processInstanceId, closeModal }) {
  const queryClient = useQueryClient();
  const [isSendingFormData, setIsSendingFormData] = useState(false);

  const mutation = useMutation({
    mutationFn: async (formData) => {
      try {
        const res = await callApi("task/complete", "post", formData);

        if (res) {
          toast.success("Task completed successfully !");
          closeModal();
        } else {
          toast.error("Task failed");
          setIsSendingFormData(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setIsSendingFormData(false);
        toast.error("An error occurred while completing the task.");
      }
    },
    onSuccess: () => {
      // Refetch the query after successful mutation
      queryClient.invalidateQueries("getAllTasks");
    },
  });

  const fetchVariables = async () => {
    if (processInstanceId) {
      const form = await callApi(
        `process/getVariablesforProcess?processInstanceId=${processInstanceId}`,
      );
      return form;
    }

    return {};
  };

  const { data: variables, isLoading: isLoadingVariables } = useQuery({
    queryKey: ["getProcessvariables"],
    queryFn: fetchVariables,
  });

  const formRef = useRef(null);

  useEffect(() => {
    const schema = JSON.parse(formInputs?.form?.schema);
    const formContainer = document.getElementById("form-container");
    formRef.current = new Form({
      container: formContainer,
    });

    if (!isLoadingVariables && variables !== undefined)
      formRef.current.importSchema(schema, variables);

    // Event listener for form submission
    formRef.current.on("submit", (event) => {
      if (JSON.stringify(event.errors) === "{}") {
        setIsSendingFormData(true);
        try {
          mutation.mutate({ taskId, variables: event.data });
          queryClient.invalidateQueries(["getAllTasks"]);
        } catch (error) {
          setIsSendingFormData(false);
          console.log(error);
          toast.error("Error sendinf form !");
        }
      } else {
        setIsSendingFormData(false);
        toast.error("Fields with * are required !");
      }

      // console.log("Form submitted:", event.data, event.errors);
    });
  }, [taskId, isLoadingVariables]); // Run this effect only once on mount

  return (
    <div>
      <ToastContainer />
      <div id="form-container" />
      {isLoadingVariables ? (
        "Form is loading..."
      ) : (
        <button
          disabled={isSendingFormData}
          className="btn btn-primary"
          style={{ display: "flex", margin: "0 auto" }}
          type="button"
          onClick={() => formRef.current.submit()}
        >
          Submit
        </button>
      )}
    </div>
  );
}

export default RenderForm;
