const dateFormat = (dateToFormat) => {
  const date = new Date(dateToFormat);

  // Use the Date methods to get day, month, and year
  const day = date.getDate();
  const month = date.getMonth() + 1; // Note: Months are zero-based, so we add 1
  const year = date.getFullYear();

  // Create a formatted date string
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
};

export default dateFormat;
