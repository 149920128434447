import axios from "axios";

const callApi = async (endpoint, method = "get", data = {}) => {
  const apiBaseUrl = "http://camunda.selectbest.net/api/v1.0";
  const axiosInstance = axios.create();

  try {
    const response = await axiosInstance({
      method,
      url: `${apiBaseUrl}/${endpoint}`,
      data,
    });

    return response.data;
  } catch (error) {
    console.error(`Error in ${method} ${endpoint}:`, error);
    throw error;
  }
};

export default callApi;
