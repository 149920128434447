/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { useState } from "react";
import "./tabulator_simple.css";
import { ReactTabulator } from "react-tabulator";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ToastContainer, toast } from "react-toastify";
import dateFormat from "../utils/dateFormat";
import Form from "./RenderForm";
import ModalContainer from "./common/Modal";
import callApi from "../utils/callApi";

function TasksList({ data, user }) {
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const queryClient = useQueryClient();

  const fetchTaskForm = async () => {
    if (selectedRowData && selectedRowData?.formKey) {
      const form = await callApi(
        `task/renderTaskForm?formId=${selectedRowData?.formKey
          ?.split(":")
          .pop()}&processDefinitionId=${selectedRowData?.processDefinitionId}`,
      );
      return form;
    }

    return {};
  };

  const {
    data: taskForm,
    isLoading: formDataIsLoading,
    refetch,
  } = useQuery({
    queryKey: ["taskForm", selectedRowData],
    queryFn: fetchTaskForm,
    enabled: !!selectedRowData,
  });

  const handleRowClick = async (e, row) => {
    const { id, name, taskState, formKey, assignee } = row.getData();

    if (
      taskState === "CREATED" &&
      formKey !== null &&
      assignee === user?.email
    ) {
      setSelectedRowData(row.getData());
      setIsModalOpen(true);
      refetch();
    } else if (
      taskState === "CREATED" &&
      assignee !== null &&
      assignee !== user?.email
    ) {
      try {
        await callApi("task/unassign", "post", {
          taskId: id,
        }).then(async (response) => {
          if (response?.unclaimTask) {
            try {
              await callApi("task/assign", "post", {
                taskId: id,
                assignee: user?.email,
              });
              row.getElement().style.backgroundColor = "orange";
              toast.success(`Assignee updated for task : ${name}`);
              // Invalidate the query to trigger a refetch
              queryClient.invalidateQueries(["getAllTasks"]);
            } catch (error) {
              toast.error("Error updating assignee");
              console.error("Error updating assignee:", error);
            }
          } else {
            toast.success(`Assignee updated for task : ${name}`);
          }
        });
      } catch (error) {
        toast.error("Error updating assignee");
        // Handle the error, e.g., show an error message to the user
        console.error("Error updating assignee:", error);
      }
    } else if (taskState === "CREATED" && assignee === null) {
      try {
        await callApi("task/assign", "post", {
          taskId: id,
          assignee: user?.email,
        });
        toast.success(`Assignee updated for task : ${name}`);
        // Invalidate the query to trigger a refetch
        queryClient.invalidateQueries(["getAllTasks"]);
      } catch (error) {
        toast.error(`Error assign for task : ${name}`);
      }
    } else if (
      taskState !== "COMPLETED" &&
      formKey === null &&
      assignee !== null
    ) {
      try {
        const res = await callApi("tasks/complete", "post", { taskId: id });

        if (res) {
          toast.success("Task completed successfully!");
          // Invalidate the query to trigger a refetch
          queryClient.invalidateQueries(["getAllTasks"]);
        } else {
          toast.error("Error while completing task!");
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred while completing the task.");
      }
    }
  };

  const tasksListColumns = [
    {
      title: "Id",
      field: "id",
      width: 100,
      editor: "input",
    },
    {
      title: "Task Name",
      field: "name",
      width: 250,
      headerFilter: "input",
    },
    {
      title: "Assignee",
      field: "assignee",
      width: 180,
      headerFilter: "input",
      editor: user?.groupNames?.includes("Administrator") ? "input" : "",
      cellEdited: async (cell) => {
        // Get the old value before updating
        const oldAssignee = cell.getOldValue();
        const updatedAssignee = cell.getData().assignee;
        const taskId = cell.getData().id;
        const taskName = cell.getData().name;

        if (oldAssignee !== null && oldAssignee !== "") {
          try {
            await callApi("task/unassign", "post", {
              taskId,
            }).then(async (response) => {
              if (response?.unclaimTask && updatedAssignee !== "") {
                try {
                  await callApi("task/assign", "post", {
                    taskId,
                    assignee: updatedAssignee,
                  });
                  toast.success(`Assignee updated for task : ${taskName}`);
                  if (updatedAssignee === user?.email)
                    cell.getRow().getElement().style.backgroundColor = "orange";
                  else
                    cell.getRow().getElement().style.backgroundColor = "white";
                  // Invalidate the query to trigger a refetch
                  queryClient.invalidateQueries(["getAllTasks"]);
                } catch (error) {
                  toast.error("Error updating assignee");
                  console.error("Error updating assignee:", error);
                }
              } else {
                toast.success(`Assignee updated for task : ${taskName}`);
              }
            });
          } catch (error) {
            toast.error("Error updating assignee");
            // Handle the error, e.g., show an error message to the user
            console.error("Error updating assignee:", error);
          }
        } else if (updatedAssignee !== "") {
          try {
            await callApi("task/assign", "post", {
              taskId,
              assignee: updatedAssignee,
            });
            toast.success(`Assignee updated for task : ${taskName}`);
            // Invalidate the query to trigger a refetch
            queryClient.invalidateQueries(["getAllTasks"]);
          } catch (error) {
            console.error(error);
            toast.error(`Error assign for task : ${taskName}`);
          }
        }
      },
    },

    ...(user?.groupNames?.includes("Administrator")
      ? [
          {
            title: "Group Assignee",
            field: "candidateGroups",
            width: 190,
            headerFilter: "input",
            editor: "input",
          },
        ]
      : []),
    {
      title: "Process Name",
      field: "processName",
      width: 150,
      headerFilter: "input",
    },
    {
      title: "Date",
      width: 90,
      field: "creationTime",
      sorter: "date",
      formatter: (cell) => {
        return dateFormat(cell.getValue());
      },
    },
    {
      title: "Status",
      field: "taskState",
      width: 80,
    },
    {
      title: "Action",
      field: "taskState",
      width: 130,
      formatter: (cell) => {
        if (cell.getValue() === "CREATED") {
          if (
            cell?.getRow()?.getData()?.assignee !== user?.email &&
            cell?.getRow()?.getData()?.assignee !== null
          ) {
            return "<button class='btn btn-secondary btn-sm btn-take-form'>Take the Task</button>";
          }
          return cell?.getRow()?.getData()?.assignee !== null
            ? `<button class="btn btn-primary btn-sm btn-take-form">${
                cell?.getRow()?.getData()?.formKey !== null
                  ? "Take the Form"
                  : "Complete"
              }</button>`
            : "<button  class='btn btn-primary btn-sm btn-take-form'>Assign to me</button>";
        }

        return "";
      },
      cellClick: handleRowClick,
    },

    // {
    //   title: "Action",
    //   formatter: () => {
    //     return '<button class="btn btn-primary btn-sm btn-take-form">Take the Form</button>';
    //   },
    //   width: 150,
    //   align: "center",
    //   cellClick: handleRowClick,
    // },
  ];

  return (
    <div style={{ padding: "10px" }}>
      <ToastContainer />

      <ReactTabulator
        paginationCounter="rows"
        pagination="local" // Enable pagination
        paginationInitialPage={1} // Start on page 1
        paginationSize={3} // Number of rows per page
        paginationSizeSelector={[3, 6, 8, 10]} // Number of rows per page options
        pageLoaded={(page) => {
          // Handle page load, you can fetch new data for the specified page here
          console.log(`Page ${page} loaded`);
        }}
        columns={tasksListColumns}
        data={data}
        rowFormatter={(row) => {
          // Apply light gray background for every other row
          if (
            row.getData()?.assignee === user?.email &&
            row.getData()?.taskState === "CREATED"
          ) {
            row.getElement().style.backgroundColor = "orange";
          } else if (
            row.getData()?.assignee === null &&
            row.getData()?.taskState === "CREATED"
          ) {
            row.getElement().style.backgroundColor = "#9DDFFF";
          } else if (row.getPosition() % 2 === 1) {
            row.getElement().style.backgroundColor = "#f0f0f0";
          }
        }}
        autoColumns // Set the width to match content
        placeholder="No data available" // Display a placeholder when there's no data
        options={{
          layout: "fitDataFill", // Adjust column widths to fill the container
          pagination: "local",
          paginationSize: 10,
          paginationSizeSelector: [10, 20, 50, 100],
          movableColumns: true,
          paginationCounter: "rows",
          ajaxLoaderLoading: "<span>Loading Data</span>",
        }}
        // style={tableStyle}
      />

      <ModalContainer isModalOpen={isModalOpen} closeModal={closeModal}>
        {!formDataIsLoading && taskForm && (
          <Form
            // variables={selectedRowData?.variables}
            processInstanceId={selectedRowData?.processInstanceId}
            taskId={selectedRowData?.id}
            formInputs={taskForm}
            closeModal={closeModal}
          />
        )}
      </ModalContainer>
    </div>
  );
}

export default TasksList;
